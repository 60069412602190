import React from 'react'
import style from './CloseSlideButton.module.css';
import Architecture from '../../pages/Architecture/Architecture';

const CloseSlideButton =  ( props ) => {
        
       
        return(
        <div className={style['close']} onClick={props.tryckte}>
        
        </div>
        
);}


export default CloseSlideButton;