import React from 'react'
import styles from './Home.module.css';
import { useLocation } from 'react-router-dom';
import App from '../../App';

const Home = ( props ) => {

    
    return (
           <>
           
           <div className={styles['header-background']}>
           
           </div>
           
        </>
    )
}

export default Home;
