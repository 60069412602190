import React from 'react'
import styles from './Overlay.module.css';

const Overlay = () => {
    return (
        <div className={[styles['overlay']]}>
            
        </div>
    )
}

export default Overlay;

